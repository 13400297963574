import {
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	AfterViewInit
} from '@angular/core';
import {CommonService} from './core/services/common.service';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MessagingService} from './core/services/messaging.service';
import {MatDialog} from '@angular/material/dialog';
import {ChatService} from './module/chat-history/chat.service';
import {ToastrService} from 'ngx-toastr';
import {filter} from 'rxjs/operators';
import {VideoCallService} from './module/call-to-user/video-call.service';
import {AppConstant} from "./core/services/constant";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnDestroy, AfterViewInit, OnInit {
	title = 'astrologer-angular';
	myAudio;
	secondAudio;
	onlineTimeAudio;
	onlineTimeAudioMin;
	currentComponent: string;
	currentUrl: string;
	activeUrl: string;
	firstPayLoadData;
	firstPayLoad;
	firebaseMessage: Subscription;
	@ViewChild('chatCallKit') chatCallKitTemplate: TemplateRef<any>;
	@ViewChild('videoCallKit') videoCallKitTemplate: TemplateRef<any>;
	@ViewChild('showLocation') showLocationDialog: TemplateRef<any>;

	constructor(
		private router: Router,
		private chatService: ChatService,
		private videoCallService: VideoCallService,
		public dialog: MatDialog,
		public common: CommonService,
		private toaster: ToastrService,
		private messagingService: MessagingService) {
	}

	ngOnInit() {
		this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
			this.currentUrl = this.activeUrl = e.url;
		});
		this.firebaseMessage = this.messagingService.getNotification().subscribe((payload) => {
			this.handleNotification(payload);
		});
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			const element = document.getElementById('scrollingToTop');
			element?.scrollTo(0, 0);
		});
	}

	ngAfterViewInit() {
		this.loadAudio();
		this.loadSecondAudio();
		this.loadNextOnlineTimeAudio();
		this.loadNextOnline10MinAudio();
	}

	ngOnDestroy(): void {
		if (this.firebaseMessage) {
			this.firebaseMessage.unsubscribe();
		}
	}

	public onRouterOutletActivate($event) {
		this.currentComponent = $event.constructor.name;
	}

	loadAudio() {
		this.myAudio = new Audio();
		this.myAudio.src = 'https://aws.astrotalk.com/assets/audio/ringing.mp3';
		this.myAudio.load();
	}

	loadSecondAudio() {
		this.secondAudio = new Audio();
		this.secondAudio.src = './assets/audio/secondAlert.mpeg';
		this.secondAudio.load();
	}

	loadNextOnlineTimeAudio() {
		this.onlineTimeAudio = new Audio();
		this.onlineTimeAudio.src = './assets/audio/nextOnlineTime.mpeg';
		this.onlineTimeAudio.load();
	}

	loadNextOnline10MinAudio() {
		this.onlineTimeAudioMin = new Audio();
		this.onlineTimeAudioMin.src = './assets/audio/comeInNext10min.mpeg';
		this.onlineTimeAudioMin.load();
	}

	playAudio = () => {
		this.myAudio.currentTime = 0;
		this.myAudio.play();
	}

	playSecondAudio = () => {
		this.secondAudio.currentTime = 0;
		this.secondAudio.play();
	}

	playNextOnlineTime = () => {
		this.onlineTimeAudio.currentTime = 0;
		this.onlineTimeAudio.play();
	}

	playNextOnlineTimeMins = () => {
		this.onlineTimeAudioMin.currentTime = 0;
		this.onlineTimeAudioMin.play();
	}

	stopAudio = () => {
		this.myAudio.currentTime = 0;
		this.myAudio.pause();
	}

	stopSecondAudio = () => {
		this.secondAudio.currentTime = 0;
		this.secondAudio.pause();
	}

	openChatCallKit = (chatData, type) => {
		if (type == 'chat_second_alert' || type == 'voip_call_second_alert' || type == 'offline_join_waitlist') {
			this.playSecondAudio();
		} else {
			this.playAudio();
		}
		return this.dialog.open(this.chatCallKitTemplate, {
			data: chatData,
			minWidth: 400,
			maxWidth: 400,
			disableClose: true
		});
	}

	closeChatCallKit() {
		this.dialog.closeAll();
	}

	checkChatStatus = (type) => {
		if (type == 'chatPerMin_status') {
			this.chatService.checkChatStatus({orderId: this.firstPayLoadData.chatOrderId}).subscribe((response: any) => {
				if (response.status == 'success' && response.data?.chatStatus == 'ASK') {
					response.data.userPic = (this.firstPayLoadData.pic) ? this.firstPayLoadData.pic :
						'https://aws.astrotalk.com/assets/images/astrolger/webp/astro-background-gray.webp';
					response.data.userName = this.firstPayLoadData.name;
					this.openChatCallKit(response.data, type);
				} else {
					this.toaster.error(response.reason);
				}
			}, () => {
				this.toaster.error('Oops! Something went wrong, please try again later.');
			});
		} else if (type == 'chat_second_alert') {
			this.closeChatCallKit();
			this.chatService.checkChatStatus({orderId: this.firstPayLoadData.chatOrderId}).subscribe((response: any) => {
				if (response.status == 'success' && response.data?.chatStatus == 'ASK') {
					response.data.userPic = (this.firstPayLoadData.pic) ? this.firstPayLoadData.pic :
						'https://aws.astrotalk.com/assets/images/astrolger/webp/astro-background-gray.webp';
					this.openChatCallKit(response.data, type);
				} else {
					this.toaster.error(response.reason);
				}
			}, () => {
				this.toaster.error('Oops! Something went wrong, please try again later.');
			});
		} else if (type == 'voip_call_second_alert') {
			this.closeChatCallKit();
			this.chatService.checkChatStatus({orderId: this.firstPayLoadData.chatOrderId}).subscribe((response: any) => {
				if (response.status == 'success' && response.data?.chatStatus == 'ASK') {
					response.data.userPic = (this.firstPayLoadData.pic) ? this.firstPayLoadData.pic :
						'https://aws.astrotalk.com/assets/images/astrolger/webp/astro-background-gray.webp';
					this.openChatCallKit(response.data, type);
				} else {
					this.toaster.error(response.reason);
				}
			}, () => {
				this.toaster.error('Oops! Something went wrong, please try again later.');
			});
		} else if (type == 'offline_join_waitlist') {
			this.closeChatCallKit();
			this.chatService.checkChatStatus({orderId: this.firstPayLoadData.chatOrderId}).subscribe((response: any) => {
				if (response.status == 'success' && response.data?.chatStatus == 'ASK') {
					response.data.userPic = (this.firstPayLoadData.pic) ? this.firstPayLoadData.pic :
						'https://aws.astrotalk.com/assets/images/astrolger/webp/astro-background-gray.webp';
					this.openChatCallKit(response.data, type);
				} else {
					this.toaster.error(response.reason);
				}
			}, () => {
				this.toaster.error('Oops! Something went wrong, please try again later.');
			});
		}
	}

	/*checkLocationAndAcceptChat(payload) {
		this.common.getPosition().then(pos => {
			const localLat = this.common.getSessionItem('geoLocationLat');
			const localLong = this.common.getSessionItem('geoLocationLong');
			if (localLat != pos.lat || localLong != pos.lng) {
				this.sendLocationToBackend(pos.lat, pos.lng, payload.orderId);
			}
			this.acceptChat(payload);
		}, (onRejected) => {
			console.log('rejection', onRejected);
			this.openPopupToAcceptLocation()
		});
	}*/

	checkLocationAndAccept(payload, chatOrCall) {
		this.common.getPosition().then(pos => {
			const localLat = this.common.getSessionItem('geoLocationLat');
			const localLong = this.common.getSessionItem('geoLocationLong');
			if (localLat != pos.lat || localLong != pos.lng) {
				this.sendLocationToBackend(pos.lat, pos.lng, payload.orderId);
			}
			if (chatOrCall == 'call') {
				this.acceptVideoCall(payload);
			} else {
				this.acceptChat(payload);
			}
		}, (onRejected) => {
			console.log('rejection', onRejected);
			this.openPopupToAcceptLocation()
		});
	}

	openPopupToAcceptLocation() {
		this.dialog.open(this.showLocationDialog, {
			maxWidth: 400,
			minWidth: 400,
			data: {
				heading: 'Allow Location Permission',
				message: 'Please allow location permission from top left corner from your browser',
			}
		});
	}

	sendLocationToBackend(lat, long, orderId = "") {
		const data = {
			consultantId: this.common.getAstroData('id'),
			lat: lat,
			lon: long,
			orderId: orderId,
			country: '',
			state: ''
		}
		this.chatService.sendLocationToBackEnd(data).subscribe((res: any) => {
			if (res.status == 'success') {
				this.common.setSessionItem('geoLocationLat', lat);
				this.common.setSessionItem('geoLocationLong',long);
			}
			return false;
		});
		return true;
	}

	acceptChat(payload) {
		this.chatService.acceptChat({chatOrderId: payload.orderId}).subscribe((res: any) => {
			this.stopAudio();
			this.stopSecondAudio();
			if (res.status == 'success') {
				const encodedUserId = this.common.customEncode(payload.userId);
				const encodedChatOrderID = this.common.customEncode(payload.orderId);
				const encodedUserName = this.common.customEncode(payload.userName);
				this.closeChatCallKit();
				this.router.navigate(['chat-with-user/' + encodedUserId + '/' + encodedChatOrderID + '/' + encodedUserName]);
			} else {
				this.toaster.error(res.reason);
			}
		}, (err) => {
			this.stopAudio();
			this.stopSecondAudio();
			console.error(err);
		});
	}

	rejectChat(payload) {
		this.chatService.rejectChat({chatOrderId: payload.orderId}).subscribe((res: any) => {
			this.stopAudio();
			this.stopSecondAudio();
			if (res.status != 'success') {
				this.toaster.error(res.reason);
			}
		}, () => {
			this.stopAudio();
			this.stopSecondAudio();
			this.toaster.error('Oops! Something went wrong, please try again later.');
		});
	}

	handleNotification(payload) {
		console.log("notification", payload);
		if (payload && payload.data) {
			this.firstPayLoadData = payload.data;
			if (this.firstPayLoadData?.type == 'general') {
				this.checkChatStatus('general');
			} else if (this.firstPayLoadData?.type == 'VIDEO_CALL') {
				this.checkVideoCallStatus(this.firstPayLoadData);
			} else if (this.firstPayLoadData?.type == 'offline_join_waitlist') {
				this.checkChatStatus('offline_join_waitlist');
			} else if (this.firstPayLoadData?.type == 'commonNotifToAstrologer') {
				this.checkChatStatus('commonNotifToAstrologer');
			} else if (this.firstPayLoadData?.type == 'chatPerMin_status') {
				this.checkChatStatus('chatPerMin_status');
			} else if (this.firstPayLoadData?.type == 'chat_second_alert') {
				this.checkChatStatus('chat_second_alert');
			} else if (this.firstPayLoadData?.type == 'chatPerMin_to_consultant') {
				this.checkChatStatus('chatPerMin_to_consultant');
			} else if (this.firstPayLoadData?.type == 'voip_call_second_alert') {
				this.checkChatStatus('voip_call_second_alert');
			} else if (this.firstPayLoadData?.type == 'updateNextOnlineTime') {
				this.playNextOnlineTime();
			} else if (this.firstPayLoadData?.type == 'nextOnlineTimeIn10Mins') {
				this.playNextOnlineTimeMins();
			} else if (this.firstPayLoadData?.type == 'consultantAdminChatTemp') {
				if (this.activeUrl != '/astrologer-interview' && this.activeUrl != '/astrologer-interview?tempID=' + payload?.data?.consultantTempId + '&interviewName=' + payload?.data?.name) {
					this.chatInterviewNotification(payload);
				}
			} else if (this.firstPayLoadData?.type == 'noticeBoardNotif') {
				this.redirectToNoticeBoard(payload);
			} else {
				const notifications: Notification = new Notification(payload.data.body ? payload.data.body : 'Notification From Astrotalk', {
					body: payload.data.body ? payload.data.body : 'Notification From Astrotalk',
					icon: 'https://aws.astrotalk.com/assets/images/header/icons/logo.webp',
					data: payload.data
				});
				notifications.addEventListener('click', () => {
					window.open((payload.notification?.click_action) ? payload.notification.click_action : 'https://astrologer.astrotalk.com', '_blank');
				});
			}
		}else if (payload) {
			const noti: Notification = new Notification(payload.notification?.title, {
				body: payload.notification?.body,
				icon: (payload.notification?.pic) ? payload.notification?.pic : AppConstant.frontDomain + '/assets/images/header/icons/logo.png',
				data: payload.notification
			});
			noti.addEventListener('click', () => {
				window.open((payload.notification?.click_action) ? payload.notification.click_action : 'https://astrologer.astrotalk.com', '_blank');
			});
		}
	}

	chatInterviewNotification(payload) {
		const clickAction = AppConstant.frontDomain + 'astrologer-interview?tempID=' + payload?.data?.consultantTempId + '&interviewName=' + payload?.data?.name;
		const notifications: Notification = new Notification(payload.notification.title, {
			body: payload.notification.title,
			icon: 'https://aws.astrotalk.com/assets/images/header/icons/logo.webp',
			data: payload.data
		});
		notifications.addEventListener('click', () => {
			window.open(clickAction, '_blank');
		});
	}

	redirectToNoticeBoard(payload) {
		const clickAction = AppConstant.frontDomain + '/dashboard?noticeBoard=' + true;
		const notifications: Notification = new Notification(payload?.data?.title, {
			body: (payload?.data?.sub_title) ? (payload?.data?.sub_title?.length  < 50 ? payload.data.sub_title : (payload.data.sub_title.substring(0, 10) + payload?.data?.ctaText)) : (payload?.data?.title ? payload?.data?.title : 'Important Notice'),
			icon: 'https://aws.astrotalk.com/assets/images/header/icons/logo.webp',
			data: (payload?.data?.sub_title) ? (payload?.data?.sub_title?.length  < 50 ? payload.data.sub_title : (payload.data.sub_title.substring(0, 10) + payload?.data?.ctaText)) : (payload?.data?.title ? payload?.data?.title : 'Important Notice')
		});
		notifications.addEventListener('click', () => {
			window.open(clickAction, '_blank');
		});
	}

	changeSource(event) {
		event.target.src = 'https://aws.astrotalk.com/assets/images/astrolger/webp/astro-background-gray.webp';
	}

	checkVideoCallStatus = (payload) => {
		this.videoCallService.checkVideoCallStatus({
			consultantId: this.common.getAstroData('id'),
			appId: this.common.getAppId(),
			businessId: this.common.getBusinessId(),
			id: payload?.id
		}).subscribe((response: any) => {
			if (response.status == 'success' && (response.data?.subStatus == 'ASK' && response.data?.status == 'in-progress')) {
				if (this.openVideoCallKitDialog) {
					this.stopAudio();
					this.openVideoCallKitDialog.close();
				}
				response.data['callId'] = payload?.id;
				response.data['userPic'] = payload?.pic;
				response.data['userName'] = payload?.name;
				this.openVideoCallKit(response.data);
			} else {
				this.toaster.error(response.reason);
			}
		}, () => {
			this.toaster.error('Oops! Something went wrong, please try again later.');
		});
	}

	openVideoCallKitDialog: any;
	openVideoCallKit = (videoData) => {
		this.playAudio();
		this.openVideoCallKitDialog = this.dialog.open(this.videoCallKitTemplate, {
			data: videoData,
			minWidth: 400,
			maxWidth: 400,
			disableClose: true
		});
	}

	acceptVideoCall(payload) {
		this.videoCallService.acceptVideoCall({
			consultantId: this.common.getAstroData('id'),
			appId: this.common.getAppId(),
			businessId: this.common.getBusinessId(),
			id: payload?.callId
		}).subscribe((res: any) => {
			this.stopAudio();
			if (res.status == 'success') {
				const token = res.token;
				const callId = this.common.customEncode(payload.callId);
				this.closeChatCallKit();
				this.router.navigate(['call-to-user/' + callId], {queryParams: {'token': token}});
			} else {
				this.toaster.error(res.reason);
			}
		}, err => {
			this.stopAudio();
			console.error(err);
		});
	}

	rejectVideoCall(payload) {
		this.videoCallService.rejectVIdeoCall({
			consultantId: this.common.getAstroData('id'),
			appId: this.common.getAppId(),
			businessId: this.common.getBusinessId(),
			id: payload?.callId
		}).subscribe((res: any) => {
			this.stopAudio();
			if (res.status != 'success') {
				this.toaster.error(res.reason);
			}
		}, err => {
			this.stopAudio();
			this.toaster.error('Oops! Something went wrong, please try again later.');
		});
	}
}
